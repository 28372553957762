
.goodsManage-wrapper {
    display: flex;
    flex-direction: column;
}
.goodsMange-top {
    padding: 20px 40px 0;
    display: flex;
    align-items: center;
    .top-left {
        width: 1%;
        flex: 1;
        .left-text {
            font-size: 16px;
            margin-right: 12px;
        }
    }
}
.good-info {
    display: flex;
    align-items: center;
    .cover {
        width: 46px;
        height: 46px;
        display: flex;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .title {
        width: 1%;
        flex: 1;
        margin-left: 16px;
    }
}
.el-link + .el-link {
    margin-left: 20px;
}
::v-deep .table-header-cell {
    &:first-child {
        padding-left: 30px;
    }
}
